<template>
  <header class="relative">
    <div class="pt-2">
      <nav class="relative mx-auto flex max-w-7xl items-center justify-between px-4 sm:px-6" aria-label="Global">
        <div class="flex flex-1 items-center">
          <div class="flex w-full items-center justify-between md:w-auto">
            <router-link to="/">
              <span class="sr-only">Ladidate</span>
              <img class="h-20 w-auto sm:h-15 pr-1 pb-2" src="@/assets/ladidate.png" alt="">
            </router-link>
            <div class="-mr-2 flex items-center md:hidden">
              <button type="button"
                @click="toggleMenu"
                class="focus-ring-inset inline-flex items-center justify-center rounded-md p-2 text-slate-400 hover:bg-slate-800 focus:outline-none focus:ring-2 focus:ring-white"
                aria-expanded="false">
                <span class="sr-only">Open main menu</span>
                <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                  stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                  <path stroke-linecap="round" stroke-linejoin="round"
                    d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                </svg>
              </button>
            </div>
          </div>
          <div v-if="isMenuOpen" class="absolute top-0 right-0 w-64 h-20 bg-white shadow-md md:hidden"
             @click.stop> <!-- This stops the click event from propagating to the parent -->
          
            <!-- Add your mobile menu items here -->
            <router-link class="p-2" to="/" @click.native="toggleMenu">Home</router-link><br/>
            <router-link class="p-2" to="/integrations" @click.native="toggleMenu">News & Press</router-link><br/>
            <router-link class="p-2" to="/vision" @click.native="toggleMenu">Vision and Mission</router-link><br/>
            <!-- ... other menu items ... -->
          </div>
          <div class="hidden space-x-8 md:ml-10 md:flex">
            <!-- Desktop menu items -->
            <router-link to="/" class="text-base font-medium text-teal-700 hover:text-teal-900">Home</router-link>
            <router-link to="/integrations" class="text-base font-medium text-teal-700 hover:text-teal-900">News & Press</router-link>
            <a href="#" class=" inline text-base italic font-medium text-gray-600 hover:text-teal-900">Partners & Selling Points
              <div class="inline pb-2">
                <span class="bg-slate-600 text-gray-100 text-xs mr-2 px-1.5 py-0.5 rounded">coming soon</span>
              </div>
            </a>
            <router-link to="/vision" class="text-base font-medium text-teal-700 hover:text-teal-900">Vision and Mission</router-link>
          </div>
        </div>
      </nav>
    </div>
  </header>
</template>

<script>
export default {
  data() {
    return {
      isMenuOpen: false
    }
  },
  methods: {
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    },
    outsideClick(event) {
      if (!this.$el.contains(event.target)) {
        this.isMenuOpen = false;
      }
    },
    // if one item of menu is clicked, close the menu
    closeMenu() {
      this.isMenuOpen = false;
    }
  },
  mounted() {
    document.addEventListener('click', this.outsideClick);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.outsideClick);
  }
}
</script>

<!-- Add any styles if required -->
<style scoped>
  /* ... your styles here ... */
</style>
