<template>


  <AnimationsVue />
  <Boxes />
  <!-- Feature section with screenshot -->

  <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.min.css">
<a href="https://wa.me/212665373600" class="float" target="_blank">
<i class="fa fa-whatsapp my-float"></i>
</a>

  <div class="relative bg-slate-50 py-16 sm:py-24 lg:py-32 before:absolute before:hidden lg:before:block before:bottom-0 before:left-0 before:w-full before:h-full 
          before:bg-black before:clip-path-polygon-[0_79%,100%_30%,100%_100%,0%_100%]
          before:bg-gradient-to-r before:from-cyan-100 before:to-green-100">
    <div class="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
      <div>
        <p v-motion :initial="{ opacity: 0, scale: 0, y: 100 }" :visibleOnce="{ opacity: 1, y: 0, scale: 1 }"
          class="mt-2 text-3xl font-bold tracking-tight text-slate-900 sm:text-4xl max-w-2xl mx-auto capitalize">Crafting Quality: The Journey of Our Dried Fruit</p>
        <p v-motion :initial="{ opacity: 0, scale: 0, y: 100 }" :visibleOnce="{ opacity: 1, y: 0, scale: 1 }"
          :delay="400" class="inline-block mx-auto mt-5 text-lg text-slate-500 max-w-3xl">See how we use sustainable and eco-friendly processes throughout the production of our dried fruit, from sourcing the fruit to drying and packaging it, while also maintaining the highest quality standards.
          <div><img src="@/assets/labels.png" class="inline w-100 h-20 mt-2"></div></p>
      </div>
      <div class="mt-12 shadow-lg">
        <!-- <img class="rounded-lg shadow-xl ring-1 ring-black ring-opacity-5" src="../assets/Group167.png" alt=""> -->
        <!-- <video class="rounded-lg shadow-xl ring-1 ring-black ring-opacity-5" src="https://synthesia-ttv-data.s3-eu-west-1.amazonaws.com/video_data/550381c5-2328-45f1-a494-fc4291dd3152/transfers/target_transfer.mp4#/" alt="" autoplay loop muted></video> -->
        <div class="rounded-lg shadow-xl ring-1 ring-black ring-opacity-5">
          <div style="position: relative; overflow: hidden; padding-top: 56.25%;"><iframe src="https://www.youtube.com/embed/LHrCRb40GbY" title="Ladidate" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen style="position: absolute; width: 100%; height: 100%; top: 0; left: 0; border: none; padding: 0; margin: 0; overflow:hidden;"></iframe></div>
        </div>
      </div>
    </div>
  </div>
  <Numbers />
  <!-- Feature section with grid -->
  <div class="relative bg-white py-16 sm:py-24 lg:py-32">
    <div class="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
      <p v-motion-pop-visible-once :delay="300"
        class="mt-2 text-3xl font-bold tracking-tight text-slate-900 sm:text-4xl">Fruitfully Sustainable: A Delicious and Nutritious Snack Option</p>
      <p v-motion-pop-visible-once :delay="300" class="mx-auto mt-5 max-w-prose text-xl text-slate-500">Dried fruit is a nutritious and convenient snack that is rich in fiber, vitamins, and minerals and can help protect your body from free radicals. Its long shelf life and lack of need for refrigeration make it a great choice for busy individuals and parents, and its concentrated flavor and chewy texture make it a tasty snack option."</p>
      <div class="mt-12">
        <div class="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-2">
          <div class="pt-6" v-motion :initial="{ opacity: 0, y: 100 }" :visibleOnce="{ opacity: 1, y: 0 }" :delay="500">
            <div class="flow-root rounded-lg bg-slate-800 px-6 pb-8">
              <div class="-mt-6">
                <div>
                  <span
                    class="inline-flex items-center justify-center rounded-md bg-gradient-to-r from-cyan-500 via-teal-500 to-green-500 p-3 shadow-lg">
                    <!-- Heroicon name: outline/cloud-arrow-up -->
                    <svg class="h-12 w-12 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                       aria-hidden="true">
                      <path fill="none" d="M0 0H24V24H0z" />
                      <path
                        d="M21 3v2c0 9.627-5.373 14-12 14H5.243C5.08 19.912 5 20.907 5 22H3c0-1.363.116-2.6.346-3.732C3.116 16.974 3 15.218 3 13 3 7.477 7.477 3 13 3c2 0 4 1 8 0zm-8 2c-4.418 0-8 3.582-8 8 0 .362.003.711.01 1.046 1.254-1.978 3.091-3.541 5.494-4.914l.992 1.736C8.641 12.5 6.747 14.354 5.776 17H9c6.015 0 9.871-3.973 9.997-11.612-1.372.133-2.647.048-4.22-.188C13.627 5.027 13.401 5 13 5z"
                        fill="rgba(255,255,255,1)" />
                    </svg>
                  </span>
                </div>
                <h3 class="mt-8 text-2xl font-bold tracking-tight text-slate-100">Healthy</h3>
                <p class="mt-5 mx-5 text-xl text-slate-300">Dried fruit is a nutritious snack rich in fiber, vitamins, and minerals. It is also a good source of antioxidants, which can help protect your body from damage caused by free radicals.</p>
              </div>
            </div>
          </div>
          <div class="pt-6" v-motion :initial="{ opacity: 0, y: 100 }" :visibleOnce="{ opacity: 1, y: 0 }"
            :delay="1000">
            <div class="flow-root rounded-lg bg-slate-800 px-6 pb-8">
              <div class="-mt-6">
                <div>
                  <span
                    class="inline-flex items-center justify-center rounded-md bg-gradient-to-r from-cyan-500 via-teal-500 to-green-500 p-3 shadow-lg">
                    <!-- Heroicon name: outline/lock-closed -->
                    <svg class="h-12 w-12 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                      stroke="currentColor" aria-hidden="true">
                      <path d="M21 2v20h-2v-8h-3V7a5 5 0 0 1 5-5zM9 13.9V22H7v-8.1A5.002 5.002 0 0 1 3 9V3h2v7h2V3h2v7h2V3h2v6a5.002 5.002 0 0 1-4 4.9z" fill="rgba(255,255,255,1)"/>
                    </svg>
                  </span>
                </div>
                <h3 class="mt-8 text-2xl font-bold tracking-tight text-slate-100">Convenient</h3>
                <p class="mt-5 mx-5 text-xl text-slate-300">Dried fruit is a convenient snack option that is easy to take with you wherever you go. Its long shelf life and lack of need for refrigeration make it a great choice for busy individuals.</p>
              </div>
            </div>
          </div>
          <div class="pt-6" v-motion :initial="{ opacity: 0, y: 100 }" :visibleOnce="{ opacity: 1, y: 0 }"
            :delay="1500">
            <div class="flow-root rounded-lg bg-slate-800 px-6 pb-8">
              <div class="-mt-6">
                <div>
                  <span
                    class="inline-flex items-center justify-center rounded-md bg-gradient-to-r from-cyan-500 via-teal-500 to-green-500 p-3 shadow-lg">
                    <!-- Heroicon name: outline/shield-check -->
                    <svg class="h-12 w-12 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                       aria-hidden="true">
                      <path fill="none" d="M0 0h24v24H0z"/><path d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10H2l2.929-2.929A9.969 9.969 0 0 1 2 12zm4.828 8H12a8 8 0 1 0-8-8c0 2.152.851 4.165 2.343 5.657l1.414 1.414-.929.929zM8 13h8a4 4 0 1 1-8 0z" fill="rgba(255,255,255,1)"/> 
                    </svg>

                    
                  </span>
                </div>
                <h3 class="mt-8 text-2xl font-bold tracking-tight text-slate-100">Tasty</h3>
                <p class="mt-5 mx-5 text-xl text-slate-300">Our products are not only tasty, but also natural and rich in nutrients. Their texture is chewy. They make a great savory snack option and a flavorful addition to your recipes.</p>
              </div>
            </div>
          </div>
          <div class="pt-6" v-motion :initial="{ opacity: 0, y: 100 }" :visibleOnce="{ opacity: 1, y: 0 }"
            :delay="2000">
            <div class="flow-root rounded-lg bg-slate-800 px-6 pb-8">
              <div class="-mt-6">
                <div>
                  <span
                    class="inline-flex items-center justify-center rounded-md bg-gradient-to-r from-cyan-500 via-teal-500 to-green-500 p-3 shadow-lg">
                    <!-- Heroicon name: outline/cog -->
                    <svg class="h-12 w-12 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                     aria-hidden="true">
                     <path fill="none" d="M0 0h24v24H0z"/><path d="M6.235 6.453a8 8 0 0 0 8.817 12.944c.115-.75-.137-1.47-.24-1.722-.23-.56-.988-1.517-2.253-2.844-.338-.355-.316-.628-.195-1.437l.013-.091c.082-.554.22-.882 2.085-1.178.948-.15 1.197.228 1.542.753l.116.172c.328.48.571.59.938.756.165.075.37.17.645.325.652.373.652.794.652 1.716v.105c0 .391-.038.735-.098 1.034a8.002 8.002 0 0 0-3.105-12.341c-.553.373-1.312.902-1.577 1.265-.135.185-.327 1.132-.95 1.21-.162.02-.381.006-.613-.009-.622-.04-1.472-.095-1.744.644-.173.468-.203 1.74.356 2.4.09.105.107.3.046.519-.08.287-.241.462-.292.498-.096-.056-.288-.279-.419-.43-.313-.365-.705-.82-1.211-.96-.184-.051-.386-.093-.583-.135-.549-.115-1.17-.246-1.315-.554-.106-.226-.105-.537-.105-.865 0-.417 0-.888-.204-1.345a1.276 1.276 0 0 0-.306-.43zM12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10z" fill="rgba(255,255,255,1)"/>
                    </svg>
                  </span>
                </div>
                <h3 class="mt-8 text-2xl font-bold tracking-tight text-slate-100">Sustainable</h3>
                <p class="mt-5 mx-5 text-xl text-slate-300">We take pride in sourcing our products locally, minimizing food waste, and paying close attention to our carbon footprint to ensure that our business practices are environmentally responsible.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>



  <Testimonial />
  <!-- CTA Section -->
  <div class="relative bg-slate-900">
    <div class="relative h-56 bg-indigo-600 sm:h-72 md:absolute md:left-0 md:h-full md:w-1/2">
      <img class="h-full w-full object-cover"
        src="@/assets/factory.png"
        alt="">
      <div aria-hidden="true"
        class="absolute inset-0 bg-gradient-to-r from-cyan-500 via-teal-500 to-green-500 mix-blend-multiply"></div>
    </div>
    <div class="relative mx-auto max-w-md px-4 py-12 sm:max-w-7xl sm:px-6 sm:py-20 md:py-28 lg:px-8 lg:py-32">
      <div class="md:ml-auto md:w-1/2 md:pl-10">
        <h2 class="text-lg font-semibold text-slate-300">We are producing what we aimed to have on our table</h2>
        <p class="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">Fruitfully Sustainable: A Dried Fruit Brand That Cares</p>
        <p class="mt-3 text-lg text-slate-300">We are dedicated to sustainability and building everything from the ground up in an environmentally responsible way. Our factory will use eco-friendly and state-of-the-art technology to minimize our carbon footprint. We also use sustainable processes throughout our supply chain and craft our products with care and attention to detail to offer the best possible product.</p>
        <div class="mt-8">
          <form action="#" class="sm:mx-auto sm:max-w-xl lg:mx-0">
            <div class="sm:flex">
              <div class="mt-3 sm:mt-0 sm:ml-3 w-full">
                <a type="submit" href="http://eepurl.com/ihj9Hf" target="_blank"
                  class="block w-full rounded-md bg-indigo-700 py-3 px-4 font-medium text-white shadow hover:bg-blue-400 focus:ring-2 focus:ring-purple-400 focus:ring-offset-2 focus:ring-offset-slate-900">Notify
                  Me ✌️</a>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Testimonial from '@/components/Testimonials.vue'
import AnimationsVue from '@/components/Animations.vue';
import Numbers from '@/components/Numbers.vue';
import Products from '@/components/Products.vue';
import Boxes from '@/components/Boxes.vue';

export default {
  name: 'HomeView',
  components: {
    Testimonial,
    AnimationsVue,
    Numbers,
    Products, 
    Boxes
  }
}
</script>
<style>
.float{
	position:fixed;
	width:60px;
	height:60px;
	bottom:40px;
	right:40px;
	background-color:#25d366;
	color:#FFF;
	border-radius:50px;
	text-align:center;
  font-size:30px;
	box-shadow: 2px 2px 3px #999;
  z-index:100;
}

.my-float{
	margin-top:16px;
}
</style>
