<template>

<div class="bg-white">
  <!-- Testimonial section -->
  <section class="overflow-hidden">
    <div class="relative mx-auto max-w-7xl px-4 pt-20 pb-12 sm:px-6 lg:px-8 lg:py-20 ">
      <svg class="absolute top-full left-0 translate-x-80 -translate-y-24 transform lg:hidden" width="784" height="404"
        fill="none" viewBox="0 0 784 404" aria-hidden="true">
        <defs>
          <pattern id="e56e3f81-d9c1-4b83-a3ba-0d0ac8c32f32" x="0" y="0" width="20" height="20"
            patternUnits="userSpaceOnUse">
            <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
          </pattern>
        </defs>
        <rect width="784" height="404" fill="url(#e56e3f81-d9c1-4b83-a3ba-0d0ac8c32f32)" />
      </svg>

      <svg class="absolute right-full top-1/2 hidden translate-x-1/2 -translate-y-1/2 transform lg:block" width="404"
        height="784" fill="none" viewBox="0 0 404 784" aria-hidden="true">
        <defs>
          <pattern id="56409614-3d62-4985-9a10-7ca758a8f4f0" x="0" y="0" width="20" height="20"
            patternUnits="userSpaceOnUse">
            <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
          </pattern>
        </defs>
        <rect width="404" height="784" fill="url(#56409614-3d62-4985-9a10-7ca758a8f4f0)" />
      </svg>

      <div class="relative lg:flex lg:items-center">
        <div v-motion :initial="{opacity:0, x:-500}" :visibleOnce="{opacity: 1, x: 0}"  class="hidden lg:block lg:flex-shrink-0">
          <img class="h-64 w-64 rounded-full xl:h-80 xl:w-80"
            src="../assets/lamya.png"
            alt="">
        </div>

        <div class="relative lg:ml-10">
          <svg
            class="absolute top-0 left-0 h-36 w-36 -translate-x-8 -translate-y-24 transform text-indigo-200 opacity-50"
            stroke="currentColor" fill="none" viewBox="0 0 144 144" aria-hidden="true">
            <path stroke-width="2"
              d="M41.485 15C17.753 31.753 1 59.208 1 89.455c0 24.664 14.891 39.09 32.109 39.09 16.287 0 28.386-13.03 28.386-28.387 0-15.356-10.703-26.524-24.663-26.524-2.792 0-6.515.465-7.446.93 2.327-15.821 17.218-34.435 32.11-43.742L41.485 15zm80.04 0c-23.268 16.753-40.02 44.208-40.02 74.455 0 24.664 14.891 39.09 32.109 39.09 15.822 0 28.386-13.03 28.386-28.387 0-15.356-11.168-26.524-25.129-26.524-2.792 0-6.049.465-6.98.93 2.327-15.821 16.753-34.435 31.644-43.742L121.525 15z" />
          </svg>
          <blockquote class="relative">
            <div class="text-2xl font-medium leading-9 text-gray-900">
              <p
              v-motion :initial="{opacity:0, y:100}" :visibleOnce="{opacity: 1, y: 0}" :delay="500" 
              >As the CEO of fruits & veggies, I am proud to say that we are dedicated to sustainability and crafting high quality healthy snacks. We use eco-friendly processes and state-of-the-art technology in our factory, and we are committed to offering the best possible product to our customers. Thank you for choosing our company and supporting our values.</p>
            </div>
            <footer class="mt-8">
              <div class="flex">
                <div class="flex-shrink-0 lg:hidden">
                  <img class="h-12 w-12 rounded-full"
                    src="https://media.licdn.com/dms/image/C4E03AQFTaEoKbtWtiw/profile-displayphoto-shrink_800_800/0/1663418499810?e=1677715200&v=beta&t=5e69VmiuV-RKfYFk8wKBR_jNwC3lnaLS0JrNHGqnJrY"
                    alt="">
                </div>
                <div class="ml-4 lg:ml-0">
                  <div 
                  v-motion :initial="{opacity:0, y:100}" :visibleOnce="{opacity: 1, y: 0}" :delay="500" 
                  class="text-base font-medium text-gray-900">Lamya BOUZIANE</div>
                  <div 
                  v-motion :initial="{opacity:0, y:-100}" :visibleOnce="{opacity: 1, y: 0}" :delay="700" 
                  class="text-base font-medium text-indigo-600">CEO, Fruits & Veggies</div>
                </div>
              </div>
            </footer>
          </blockquote>
        </div>
      </div>
    </div>
  </section>
  </div>  
</template>