<template>
    <div class="bg-gray-100">
      <div class="w-full p-16 sm:p-24">
        <h2 v-motion :initial="{ opacity: 0, y: 100 }" :enter="{ opacity: 1, y: 0, scale: 1 }" :delay="500" class="mt-4 text-2xl font-bold tracking-tight text-white sm:mt-5 sm:text-2xl lg:mt-6 xl:text-4xl">
                  <span class="block bg-gradient-to-r from-cyan-100 via-teal-600 to-green-700 bg-clip-text pb-5 text-transparent sm:pb-5">Ladidate, The snack that packs a punch! </span>
                </h2>

        <div class="mt-8 grid grid-cols-1 gap-y-2 sm:grid-cols-2 sm:gap-x-2 lg:grid-cols-4 xl:gap-x-28">
          <div v-for="product in products" :key="product.id">
            <div class="relative">
              <div class="relative h-72 w-full overflow-hidden rounded-lg">
                <img :src="product.imageSrc" :alt="product.imageAlt" class="h-full w-90% object-cover object-center" />
              </div>
              <div class="absolute inset-x-0 top-0 flex h-72 items-end justify-end overflow-hidden rounded-lg p-4">
                <div aria-hidden="true" class="absolute inset-x-0 bottom-0 h-36 bg-gradient-to-t from-black opacity-50" />
                <p class="relative text-lg font-semibold text-white">{{ product.name }}</p>
              </div>
            </div>
          </div>
        </div>


        <div class="mt-8 grid grid-cols-1 gap-y-2 sm:grid-cols-2 sm:gap-x-2 lg:grid-cols-4 xl:gap-x-28">
          <div v-for="pouch in pouchs" :key="pouch.id">
            <div class="relative">
              <div class="relative h-72 w-full overflow-hidden rounded-lg">
                <img :src="pouch.imageSrc" :alt="pouch.imageAlt" class="h-full w-full object-cover object-center" />
              </div>
              <div class="absolute inset-x-0 top-0 flex h-72 items-end justify-end overflow-hidden rounded-lg p-4">
                <div aria-hidden="true" class="absolute inset-x-0 bottom-0 h-36 bg-gradient-to-t from-black opacity-50" />
                <p class="relative text-lg font-semibold text-white">{{ pouch.name }}</p>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </template>
  
  <script setup>
  
  const products = [
    {
      id: 1,
      href: '#',
      imageSrc: require('@/assets/kaki-boxe-2.png'),
      imageAlt: 'Front of zip tote bag with white canvas, black canvas straps and handle, and black zipper pulls.',
      name: 'Dehydrated Persimmons',
    },
    // dried strawberry
    {
      id: 2,
      href: '#',
      imageSrc: require('@/assets/fig-boxe-2.png'),
      imageAlt: 'Front of zip tote bag with white canvas, black canvas straps and handle, and black zipper pulls.',
      name: 'Dehydrated Figs',
    },
    //dried strawberry
    {
      id: 3,
      href: '#',
      imageSrc: require('@/assets/strawberry-box-2.png'),
      imageAlt: 'Front of zip tote bag with white canvas, black canvas straps and handle, and black zipper pulls.',
      name: 'Dehydrated Strawberries',
    },
    // dried banana
    {
      id: 4,
      href: '#',
      imageSrc: require('@/assets/banana-box-2.png'),
      imageAlt: 'Front of zip tote bag with white canvas, black canvas straps and handle, and black zipper pulls.',
      name: 'Dehydrated Bananas',
    },
  ]

  const pouchs = [
    {
      id: 1,
      href: '#',
      imageSrc: require('@/assets/Persimon-2.png'),
      imageAlt: 'Front of zip tote bag with white canvas, black canvas straps and handle, and black zipper pulls.',
      name: 'Dehydrated Persimmons',
    },
    // dried strawberry
    {
      id: 2,
      href: '#',
      imageSrc: require('@/assets/Figs-2.png'),
      imageAlt: 'Front of zip tote bag with white canvas, black canvas straps and handle, and black zipper pulls.',
      name: 'Dehydrated Figs',
    },
    //dried strawberry
    {
      id: 3,
      href: '#',
      imageSrc: require('@/assets/straberry-2.png'),
      imageAlt: 'Front of zip tote bag with white canvas, black canvas straps and handle, and black zipper pulls.',
      name: 'Dehydrated Strawberries',
    },
    // dried banana
    {
      id: 4,
      href: '#',
      imageSrc: require('@/assets/Banana-2.png'),
      imageAlt: 'Front of zip tote bag with white canvas, black canvas straps and handle, and black zipper pulls.',
      name: 'Dehydrated Bananas',
    },
  ]
  </script>