<template>
    <div class="bg-gray-100">
      <div class="w-full p-16 sm:p-24">
        <div class="mt-8 grid grid-cols-1 gap-y-12 sm:grid-cols-2 sm:gap-x-6 lg:grid-cols-3 xl:gap-x-28">
          <div v-for="product in products" :key="product.id">
            <div class="relative">
              <div class="relative h-72 w-full overflow-hidden rounded-lg">
                <img :src="product.imageSrc" :alt="product.imageAlt" class="h-full w-full object-cover object-center" />
              </div>
              <div class="absolute inset-x-0 top-0 flex h-72 items-end justify-end overflow-hidden rounded-lg p-4">
                <div aria-hidden="true" class="absolute inset-x-0 bottom-0 h-36 bg-gradient-to-t from-black opacity-50" />
                <p class="relative text-lg font-semibold text-white">{{ product.name }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script setup>
  
  const products = [
    {
      id: 1,
      href: '#',
      imageSrc: require('@/assets/banana.png'),
      imageAlt: 'Front of zip tote bag with white canvas, black canvas straps and handle, and black zipper pulls.',
      name: 'Dried Banana',
    },
    // dried strawberry
    {
      id: 4,
      href: '#',
      imageSrc: require('@/assets/strawberry.png'),
      imageAlt: 'Front of zip tote bag with white canvas, black canvas straps and handle, and black zipper pulls.',
      name: 'Dried Strawberry',
    },
    // dried persimmon
    {
      id: 5,
      href: '#',
      imageSrc: require('@/assets/persimmon.png'),
      imageAlt: 'Front of zip tote bag with white canvas, black canvas straps and handle, and black zipper pulls.',
      name: 'Dried Persimmon',
    },
  ]
  </script>