<template>
          <div class="pt-10 sm:pt-16 lg:overflow-hidden lg:pt-8 lg:pb-14">
        <div class="mx-auto max-w-7xl lg:px-8">
          <div class="lg:grid lg:grid-cols-2 lg:gap-8">
            <div class="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 sm:text-center lg:flex lg:items-center lg:px-0 lg:text-left">
              <div class="lg:py-24">
                <h1 v-motion :initial="{ opacity: 0, y: 100 }" :enter="{ opacity: 1, y: 0, scale: 1 }" :delay="500" class="mt-4 text-2xl font-bold tracking-tight text-white sm:mt-5 sm:text-5xl lg:mt-6 xl:text-5xl">
                  <span class="block bg-gradient-to-r from-cyan-600 via-green-600 to-teal-900 bg-clip-text pb-5 text-transparent sm:pb-5 ">Embrace the taste of Happiness ! 
                   
                  </span>
                </h1>
                <p v-motion :initial="{ opacity: 0, y: 100 }" :enter="{ opacity: 1, y: 0, scale: 1 }" :delay="800" class="inline text-base text-slate-600 sm:text-xl lg:text-lg xl:text-xl pr-2">Locally-sourced, carefully-selected fruits from Morocco, dried sustainably to retain nutrients, flavor, and texture. Enjoy the benefits of fresh fruit anytime, anywhere with our convenient, healthy, and tasty snack.
                  
                </p>
                
                <div class="mt-10 sm:mt-5">
                    <img src="@/assets/icons8-instagram-200.svg" class="inline w-20 h-20 sm:w-16 sm:h-16 lg:w-20 lg:h-20 xl:w-20 xl:h-20" alt="instagram" onclick="window.open('https://www.instagram.com/ladidate')" style="cursor: pointer;">
                </div>
                <!-- <span class="inline bg-slate-300 text-gray-800 text-sm font-medium mr-2 px-1.5 py-0.5 rounded dark:bg-gray-700 dark:text-slate-600">coming soon</span> -->
                <div class="mt-10 sm:mt-5">
                  <div class="hidden md:flex md:items-center md:space-x-6">
          <!-- <a href="#" class="text-base font-medium text-white hover:text-slate-300">Log in</a> -->

        </div><div><img src="@/assets/labels.png" class="inline w-100 h-20 mt-2"></div>
                </div>
              </div>
            </div>
            <div class="sm:-mb-20 lg:relative lg:m-0 pt-4 max-h-fit">
                <img src="@/assets/4-boxes.png" class="mt-20 rounded-lg opacity-95">
            </div>
          </div>
        </div>
      </div>
</template>