import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  // add integrations route
  {
    path: '/integrations',
    name: 'integrations',
    component: () => import(/* webpackChunkName: "integrations" */ '../views/IntegrationsView.vue')
  },
  // add product route
  {
    path: '/product',
    name: 'product',
    component: () => import(/* webpackChunkName: "product" */ '../views/ProductView.vue')
  },
  // add missionAndVision route
  {
    path: '/vision',
    name: 'vision',
    component: () => import(/* webpackChunkName: "missionAndVision" */ '../views/VisionView.vue')
  }
  
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
